export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={16}
    width={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.795 11.803A5.684 5.684 0 1 0 4.348 1.341a5.684 5.684 0 0 0 4.447 10.462ZM11 11l4 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SearchIcon.displayName = 'SearchIcon';
export default SearchIcon;
