import React from 'react';
import cx from 'classnames';
import styles from './TextField.module.scss';

type Props = {
  startElement?: React.ReactNode;
  endElement?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  noPaddingRight?: boolean;
  wrapperProps?: React.HTMLProps<HTMLDivElement>;
} & React.HTMLProps<HTMLInputElement>;

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      startElement,
      endElement,
      fullWidth,
      wrapperProps,
      className,
      disabled,
      error,
      noPaddingRight,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        {...wrapperProps}
        className={cx(
          styles.wrapper,
          {
            [styles.fullWidth]: fullWidth,
            [styles.hasContent]:
              typeof rest.value !== 'undefined' && rest.value !== '',
            [styles.disabled]: disabled,
            [styles.error]: error,
          },
          wrapperProps?.className
        )}
      >
        {startElement}
        <input
          type="text"
          className={cx(styles.input, className, {
              [styles.noPaddingRight]: noPaddingRight,
              [styles.disabled]: disabled,
          })}
          ref={ref}
          disabled={disabled}
          {...rest}
        ></input>
        {endElement}
      </div>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
