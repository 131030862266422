export const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    fill="currentColor"
    {...props}
  >
    <path d="M29.17 12 32 14.83 22.83 24 32 33.17 29.17 36l-12-12 12-12Z" />
  </svg>
);

ChevronLeft.displayName = 'ChevronLeft';
export default ChevronLeft;
