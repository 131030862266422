import React from 'react';
import ChevronDown from '@/icons/ChevronDown';
import styles from './Dropdown.module.scss';
import cx from 'classnames';
import {
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { SmdBasSrpModelsFilterOptionValue } from 'types/api';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import { flip } from '@floating-ui/dom';
import { PageVariant } from '@/utils/Platform';

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

export const Item = React.forwardRef<
  HTMLDivElement,
  ItemProps & React.HTMLProps<HTMLDivElement>
>(({ children, active, ...rest }, ref) => {
  const id = useId();
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
      className={cx(styles.item, { [styles.active]: active })}
    >
      {children}
    </div>
  );
});

Item.displayName = 'DropdownListItem';

export type IDropdownProps = {
  icon?: React.ReactNode;
  label?: string;
  values?: Array<string | number>;
  placeholder?: string;
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  onChange: (value: string | number) => void;
  onClear?: () => void;
  multiSelect?: boolean;
  disabled?: boolean;
  containerRef?: React.MutableRefObject<HTMLElement | null>;
  variant?: PageVariant;
};

export const Dropdown = ({
  label,
  values,
  options,
  onChange,
  onClear,
  placeholder,
  icon,
  multiSelect = true,
  disabled,
  containerRef,
  variant = PageVariant.SRP,
}: IDropdownProps) => {
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const listRef = React.useRef<Array<HTMLElement | null>>([]);

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    open,
    onOpenChange: !disabled ? setOpen : undefined,
    middleware: [
      flip(),
      offset(1),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minHeight: `${Math.min(rects.floating.height, 500)}px`,
            maxHeight: `${Math.max(availableHeight, 500)}px`,
          });
        },
        padding: 16,
      }),
    ],
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  const selectedOptions = React.useMemo(
    () =>
      values
        ?.map((value) => options.find((o) => o.value === value))
        .filter(Boolean),
    [values, options]
  );

  let textContent = 'Ukendt';
  if (selectedOptions && selectedOptions?.length > 0) {
    if (selectedOptions.length === options.length) {
      textContent = 'Alle';
    } else {
      textContent = selectedOptions?.map((o) => o?.name).join(', ');
    }
  } else {
    textContent = placeholder ?? 'Alle';
  }

  const hasValue = (selectedOptions?.length ?? 0) > 0;
  const hasNonDefaultValue =
    (selectedOptions?.filter((o) => !o?.isDefault).length ?? 0) > 0;

  React.useEffect(() => {
    // once a dropdown is disabled, clear the selected options;
    // to avoid an extra dispatch on mount, do it only if there are selected options already in the dropdown
    if (
      !!onClear &&
      disabled &&
      selectedOptions &&
      selectedOptions?.length > 0
    ) {
      onClear();
    }
  }, [disabled, selectedOptions, onClear]);

  return (
    <div>
      {typeof label !== 'undefined' && label.length > 0 && (
        <label className={styles.label}>{label}</label>
      )}
      <div
        {...getReferenceProps({
          ref: refs.setReference,
          className: cx(styles.wrapper, {
            [styles.hasContent]: hasValue,
            [styles.disabled]: disabled,
            [styles.frontpage]: variant === PageVariant.Frontpage,
            [styles.srp]: variant === PageVariant.SRP,
          }),
          onClick: () => (!disabled ? setOpen((prev) => !prev) : undefined),
          tabIndex: !disabled ? 0 : undefined,
          ['aria-disabled']: disabled,
        })}
      >
        {icon}
        <div
          className={cx(styles.content, {
            [styles.placeholder]: !hasValue,
          })}
        >
          {textContent}
        </div>
        <div className={cx(styles.actions, {
            [styles.frontpage]: variant === PageVariant.Frontpage
        })}>
          {variant === PageVariant.SRP && hasNonDefaultValue && !!onClear && (
            <button aria-label="Ryd">
              <LargeCloseIcon
                className={styles.icons}
                height={16}
                width={16}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClear();
                }}
                style={{ zIndex: 20 }}
              />
            </button>
          )}
          <button
            aria-label="Åbn dropdown"
            aria-disabled={disabled}
            tabIndex={!disabled ? 0 : -1}
            className={cx({[styles.disabled]: disabled})}
          >
            <ChevronDown
              className={cx(styles.icons, { [styles.open]: open })}
            />
          </button>
        </div>
      </div>
      {open && (
        <FloatingPortal root={containerRef}>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: styles.popover,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                },
              })}
            >
              {options.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Item
                  {...getItemProps({
                    key: `${option.value}${index}`,
                    ref(node) {
                      listRef.current[index] = node;
                    },
                    onClick() {
                      onChange(option.value!);

                      if (!multiSelect) {
                        setOpen(false);
                        refs.domReference.current?.focus();
                      }
                    },
                    style: { paddingLeft: 16 + (option.level ?? 0) * 16 },
                  })}
                  active={activeIndex === index}
                >
                  {multiSelect ? (
                    <>
                      <input
                        type="checkbox"
                        name={option.value + ''}
                        checked={values?.includes(option.value!) ?? false}
                        readOnly
                      />
                      {option.name}
                    </>
                  ) : (
                    option.name
                  )}
                </Item>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
