export const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.1005 0.585787C4.47558 0.210714 4.98429 9.00472e-08 5.51472 8.25009e-08L10.4853 1.17859e-08C11.0157 4.2395e-09 11.5244 0.210714 11.8995 0.585787L15.4142 4.1005C15.7893 4.47558 16 4.98429 16 5.51472V10.4853C16 11.0157 15.7893 11.5244 15.4142 11.8995L11.8995 15.4142C11.5244 15.7893 11.0157 16 10.4853 16H5.51472C4.98429 16 4.47558 15.7893 4.10051 15.4142L0.585787 11.8995C0.210714 11.5244 4.64141e-09 11.0157 1.29032e-08 10.4853L9.03222e-08 5.51472C9.85839e-08 4.98429 0.210714 4.47558 0.585787 4.10051L4.1005 0.585787Z"
      fill="#D91F0A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99927 3.25C8.41348 3.25 8.74927 3.58579 8.74927 4V9C8.74927 9.41421 8.41348 9.75 7.99927 9.75C7.58505 9.75 7.24927 9.41421 7.24927 9V4C7.24927 3.58579 7.58505 3.25 7.99927 3.25Z"
      fill="white"
    />
    <path
      d="M8.79922 11.8C8.79922 12.2418 8.44105 12.6 7.99922 12.6C7.55739 12.6 7.19922 12.2418 7.19922 11.8C7.19922 11.3582 7.55739 11 7.99922 11C8.44105 11 8.79922 11.3582 8.79922 11.8Z"
      fill="white"
    />
  </svg>
);

ErrorIcon.displayName = 'AlertErrorIcon';
export default ErrorIcon;
