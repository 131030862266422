import { createHash } from 'crypto';
import { Experiment } from '@amplitude/experiment-node-server';
import { createContext } from 'react'
import { Variant } from "@amplitude/experiment-js-client";
import { PulseBaseBehaviorEventExperiment } from "@smd/tracking";

export enum ActiveExperiments {
    ValereTest = 'relevancy-v1',
}
export const PULSE_DATA_COOKIE_NAME = '_pulse2data';

const hashId = (id?: string) => {
    if (!id) {
        return id
    }
    return createHash('sha256').update(id).digest('hex')
}

const pulse2dataIsValid = (pulse2Data: string) =>
  !!pulse2Data &&
  pulse2Data !== 'undefined' &&
  pulse2Data !== 'null' &&
  pulse2Data.split(',').length >= 3;

const getUserId = (pulse2Data = '') => {
    try {
        const pulseData = decodeURIComponent(pulse2Data).split(',')
        if (pulseData.length >= 3 && pulseData[2]) {
            return `sdrn:schibsted.dk:user:${pulseData[2]}`
        }
    } catch (error) {
        console.log('Error getting user id from pulse2 data', error)
        return ''
    }
}

const getDeviceId = (pulse2Data = '') => {
    try {
        const pulseData = decodeURIComponent(pulse2Data).split(',')
        if (pulseData.length >= 3) {
            return `sdrn:schibsted:environment:${pulseData[0]}`
        }
    } catch (error) {
        console.log('Error getting device id from pulse2 data', error)
        return ''
    }
}

const removeEmptyValues = (obj: any) => {
    Object.keys(obj).forEach((key) => !obj[key] && obj[key] !== 0 && delete obj[key])
    return obj
}
const getUserFromPulse2Data = (pulse2Data: string) => {
    const user = pulse2dataIsValid(pulse2Data)
        ? {
            device_id: hashId(getDeviceId(pulse2Data)),
            user_id: hashId(getUserId(pulse2Data)),
        }
        : {}
    return removeEmptyValues(user)
}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => !Number.isNaN(k)) as K[]
}

export type ExperimentVariant = {
    experimentName: string;
    variant: VariantValue
};

export type VariantValue = 'off' | 'control' | string;

export type ExperimentObject = {
    [key in keyof typeof ActiveExperiments]: VariantValue;
};

/**
 * Transforms the experiments array into an object with the experiment names as keys for easy use in components where experiments are implemented
 * @param experimentVariants - The experiment variants fetched from Amplitude stored in context
 */
function makeExperimentObject(experimentVariants: ExperimentVariant[]): ExperimentObject {
    let experimentObject: any = {} as ActiveExperiments;

    for (const ae of enumKeys(ActiveExperiments)) {
        const value = ActiveExperiments[ae];
        const variantValue = experimentVariants.find((variant) => variant.experimentName === value)?.variant ?? 'off';
        experimentObject[ae] = variantValue as VariantValue;
    }

    return experimentObject;
}

/**
 * Fetches the experiment variants for the given experiment names
 * @param pulse2DataCookie - The _pulse2data cookie fetched using Cookies.get(PULSE_DATA_COOKIE_NAME);
 * @param userId - Optional user id to override the user id fetched from the _pulse2data cookie. Used for testing purposes.
 */
const getExperimentVariants = async (
    pulse2DataCookie: string | undefined,
    userId = '',
): Promise<ExperimentVariant[]> => {
    if (!pulse2DataCookie) {
        return []
    }

    const user = getUserFromPulse2Data(pulse2DataCookie)
    if (userId !== '') { //This is where we should handle if a user is a test user, meaning locally running or in QA environment
        user.user_id = hashId(`sdrn:schibsted.dk:user:${userId}`)
    }

    const amplitudeClient = Experiment.initialize('server-FCPZN9oWybmJQE3JS7bETDRAZkalAzvR', {
        serverUrl: 'https://api.lab.eu.amplitude.com',
        fetchTimeoutMillis: 500,
        fetchRetries: 1,
        fetchRetryBackoffMinMillis: 0,
        fetchRetryTimeoutMillis: 500,
    })

    let experimentVariants: Record<string, Variant>;
    try {
        experimentVariants = await amplitudeClient.fetchV2(user)
    } catch (e) {
        console.error('Error fetching experiment variants', e)
        return []
    }
    return Object.values(ActiveExperiments).map((experimentName) => ({
      experimentName: experimentName,
      variant: experimentVariants[experimentName]?.value || 'off',
    }));
}

const AmplitudeExperimentsContext = createContext<ExperimentVariant[]>([])

/**
 * Creates the experiment object to be sent to Pulse for tracking
 * @param experiments - The experiment variants fetched from Amplitude stored in context
 */
const pulseExperimentObject = (experiments: ExperimentVariant[]): PulseBaseBehaviorEventExperiment[] => {
    return experiments.map((value) => {
        return {
            id: value.experimentName,
            name: value.experimentName,
            variant: value.variant,
            platform: 'bilbasen',
        };
    })
}

export { AmplitudeExperimentsContext, getExperimentVariants, makeExperimentObject, pulseExperimentObject }