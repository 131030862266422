import React from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  useTransitionStyles,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
  useId,
  UseTransitionStylesProps,
} from '@floating-ui/react';
import styles from './Dialog.module.scss';
import cx from 'classnames';
import VeryLargeCloseIcon from '@/icons/VeryLargeCloseIcon';
import ChevronLeft from '@/icons/ChevronLeft';

interface DialogOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  className?: string;
}

export function useDialog({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  disabled = false,
  className,
}: DialogOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = React.useState<string | undefined>();
  const [descriptionId, setDescriptionId] = React.useState<
    string | undefined
  >();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: disabled ? false : controlledOpen == null,
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      overlayClassName: cx(className),
    }),
    [open, setOpen, interactions, data, labelId, descriptionId, className]
  );
}

type ContextType =
  | (ReturnType<typeof useDialog> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    })
  | null;

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export function Dialog({
  children,
  ...options
}: {
  children: React.ReactNode;
} & DialogOptions) {
  const dialog = useDialog(options);
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
}

type DialogTriggerProps = {
  children: React.ReactNode;
};

export const DialogTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DialogTriggerProps
>(function DialogTrigger({ children, ...props }, propRef) {
  const context = useDialogContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return null;
});

type DialogContentProps = {
  transition?: UseTransitionStylesProps;
  initialFocus?: number;
  lockScroll?: boolean;
};

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & DialogContentProps
>(function DialogContent(
  { transition, initialFocus, className, lockScroll = true, ...props },
  propRef
) {
  const {
    context: floatingContext,
    overlayClassName,
    ...context
  } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const { isMounted, styles: floatingStyles } = useTransitionStyles(
    floatingContext,
    { ...transition }
  );
  const { styles: overlayStyles } = useTransitionStyles(floatingContext);

  return isMounted ? (
    <FloatingPortal>
      <FloatingOverlay
        className={cx(styles.overlay, overlayClassName)}
        lockScroll={lockScroll}
        style={overlayStyles}
      >
        <FloatingFocusManager
          context={floatingContext}
          initialFocus={initialFocus}
        >
          <div
            ref={ref}
            style={{ ...floatingStyles }}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps({
              className: cx(styles.dialog, className),
              ...props,
            })}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  ) : null;
});

type HeadingProps = {
  hideCloseIcon?: boolean;
  hideBackIcon?: boolean;
  subHeader?: React.ReactNode;
  clear?: () => void;
};

export const DialogHeading = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement> & HeadingProps
>(function DialogHeading(
  {
    children,
    hideCloseIcon = false,
    hideBackIcon = true,
    subHeader,
    className,
    clear,
    ...props
  },
  ref
) {
  const { setLabelId, setOpen } = useDialogContext();
  const id = useId();

  // Only sets `aria-labelledby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <div className={cx(styles.header, className)}>
      {!hideBackIcon && (
        <button
          data-e2e="go-back"
          onClick={() => setOpen(false)}
          title="Tilbage"
          className={styles.backIcon}
        >
          <ChevronLeft height={24} width={24} />
        </button>
      )}
      <h3 {...props} ref={ref} id={id}>
        {children}
      </h3>
      {clear && (
        <button
          data-e2e="clear-selected-dialog"
          title="Nulstil"
          className={styles.clear}
          onClick={clear}
        >
          Nulstil
        </button>
      )}
      {!hideCloseIcon && (
        <button
          data-e2e="close-dialog"
          onClick={() => setOpen(false)}
          title="Luk"
          className={styles.closeIcon}
        >
          <VeryLargeCloseIcon />
        </button>
      )}
      {React.isValidElement(subHeader) && (
        <div className={styles.subHeader}>{subHeader}</div>
      )}
    </div>
  );
});

export const DialogBody = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function DialogBody({ children, className, ...props }, ref) {
  const { setDescriptionId } = useDialogContext();
  const id = useId();

  // Only sets `aria-describedby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return (
    <div className={cx(styles.body, className)} {...props} ref={ref} id={id}>
      {children}
    </div>
  );
});

export const DialogActions = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
  // @ts-ignore - unused parameter, but here to prevent console warning
>(function DialogActions({ children, className, ...props }, ref) {
  return (
    <div className={cx(styles.actions, className)} {...props}>
      {children}
    </div>
  );
});

export const DialogClose = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DialogTriggerProps
  // @ts-ignore - unused parameter, but here to prevent console warning
>(function DialogClose({ children, onClick, ...props }, ref) {
  const { setOpen } = useDialogContext();

  if (!React.isValidElement(children)) return null;

  return React.cloneElement(children, {
    ...props,
    ...children.props,
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      document.getElementById('validationError')
        ? setOpen(true)
        : !props.disabled && setOpen(false); //Ideally not using document.getElementById here, but we don't have a consistent way to handle validation errors
      onClick?.(e);
      children?.props['onClick']?.(e);
    },
  });
});
