export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.244934 12.0095L6.159 1.08407C6.94142 -0.361353 9.05858 -0.361357 9.841 1.08406L15.7551 12.0095C16.4895 13.3663 15.4838 15 13.9141 15H2.08594C0.516181 15 -0.489536 13.3663 0.244934 12.0095Z"
      fill="#D5840B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V9C8.75 9.41421 8.41421 9.75 8 9.75C7.58579 9.75 7.25 9.41421 7.25 9V4C7.25 3.58579 7.58579 3.25 8 3.25Z"
      fill="white"
    />
    <path
      d="M8.8002 11.8C8.8002 12.2418 8.44202 12.6 8.0002 12.6C7.55837 12.6 7.2002 12.2418 7.2002 11.8C7.2002 11.3582 7.55837 11 8.0002 11C8.44202 11 8.8002 11.3582 8.8002 11.8Z"
      fill="white"
    />
  </svg>
);

WarningIcon.displayName = 'WarningErrorIcon';
export default WarningIcon;
