import {
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';

type SetSearchRequestAction = {
  type: 'SetSearchRequest';
  payload: SmdBasSrpModelsSearchRequest;
};

type ToggleBooleanValueAction = {
  type: 'ToggleBooleanValue';
  payload: {
    key: string;
  };
};

type SetBooleanValueAction = {
  type: 'SetBooleanValue';
  payload: {
    key: string;
    value: boolean;
  };
};

type SetSingleSelectValue = {
  type: 'SetSingleSelectValue';
  payload: {
    key: string;
    value: string;
  };
};

type SetTextValue = {
  type: 'SetTextValue';
  payload: {
    key: string;
    value: string;
  };
};

type SetNumericValue = {
  type: 'SetNumericValue';
  payload: {
    key: string;
    value: number;
  };
};

type SetNumericDoubleValue = {
  type: 'SetNumericDoubleValue';
  payload: {
    key: string;
    firstValue: number;
    secondValue: number;
  };
};

type SetRangeValue = {
  type: 'SetRangeValue';
  payload: {
    key: string;
    fromValue?: number;
    toValue?: number;
  };
};

type ToggleMultiSelectionValue = {
  type: 'ToggleMultiSelectionValue';
  payload: {
    key: string;
    value: string;
  };
};

type SetMultiSelectionValue = {
  type: 'SetMultiSelectionValue';
  payload: {
    key: string;
    values: SmdBasSrpModelsSelectedFilterValue[];
  };
};

type ToggleNestedMultiSelectionValue = {
  type: 'ToggleNestedMultiSelectionValue';
  payload: {
    key: string;
    parent: { key: string; value: string };
    value: string;
  };
};

type SetNestedMultiSelectionValue = {
  type: 'SetNestedMultiSelectionValue';
  payload: {
    key: string;
    values: SmdBasSrpModelsSelectedFilterValue[];
  };
};

type SetSorting = {
  type: 'SetSorting';
  payload: {
    sortBy: string;
    sortOrder: string;
  };
};

type SearchRequestActions =
  | SetBooleanValueAction
  | SetSearchRequestAction
  | SetSingleSelectValue
  | SetMultiSelectionValue
  | SetNestedMultiSelectionValue
  | SetTextValue
  | SetNumericValue
  | SetNumericDoubleValue
  | SetRangeValue
  | ToggleBooleanValueAction
  | ToggleMultiSelectionValue
  | ToggleNestedMultiSelectionValue
  | SetSorting;

// Immer.js allows us to assign directly to the draft, which will then be applied/patched into the state
export const searchRequestReducer = (
  draft: SmdBasSrpModelsSearchRequest,
  action: SearchRequestActions
) => {
  draft.selectedFilters ??= {};

  const { payload, type } = action;

  switch (type) {
    case 'SetSearchRequest':
      return payload;

    case 'SetBooleanValue':
      if (payload.value) {
        draft.selectedFilters[payload.key] = {
          value: {
            booleanValue: payload.value,
          },
        };
      } else {
        delete draft.selectedFilters[payload.key];
      }
      break;

    case 'SetSingleSelectValue':
      // TODO: Cleanup selectedFilters with this filter and value as parent

      // TODO: Fix this?
      // There is no `.isDefault` property available in any of the filter.options[<INDEX>].optionValues[<INDEX>] from the backend,
      // so when clearing the SingleSelect field, `defaultsForKey` method returns {} for value instead of a default valid value (see userSearch.tsx),
      // potentially causing an extra query cache in React query.
      // thus, we clear the {value: {}} of the SearchRequest here, before it reaches the backend.
      if (
        typeof payload.value === 'number' ||
        typeof payload.value === 'string'
      ) {
        draft.selectedFilters[payload.key] = {
          value: { value: payload.value },
        };
      } else {
        delete draft.selectedFilters[payload.key];
      }
      break;

    case 'SetTextValue':
      if (typeof payload.value === 'string' && !!payload.value) {
        draft.selectedFilters[payload.key] = {
          value: { textValue: payload.value },
        };
      } else {
        delete draft.selectedFilters[payload.key];
      }
      break;

    case 'SetNumericValue':
      if (typeof payload.value === 'number') {
        draft.selectedFilters[payload.key] = {
          value: { numericValue: payload.value },
        };
      } else {
        delete draft.selectedFilters[payload.key];
      }
      break;

    case 'SetNumericDoubleValue':
      if (
        typeof payload.firstValue === 'number' &&
        typeof payload.secondValue === 'number'
      ) {
        draft.selectedFilters[payload.key] = {
          value: {
            firstValue: payload.firstValue,
            secondValue: payload.secondValue,
          },
        };
      } else {
        delete draft.selectedFilters[payload.key];
      }
      break;

    case 'SetRangeValue':
      if (
        typeof payload.fromValue === 'undefined' &&
        typeof payload.toValue === 'undefined'
      ) {
        delete draft.selectedFilters[payload.key];
      } else {
        draft.selectedFilters[payload.key] = {
          value: {
            fromValue: payload.fromValue,
            toValue: payload.toValue,
          },
        };
      }
      break;

    // TODO: Is this used? Seems that only ToggleMultiSelectionValue is
    case 'SetMultiSelectionValue':
      draft.selectedFilters[payload.key] = {
        values: payload.values,
      };
      break;

    // TODO: Is this used?
    case 'SetNestedMultiSelectionValue':
      draft.selectedFilters[payload.key] = {
        values: payload.values,
      };
      break;

    // TODO: Is this used? Seems that only SetBooleanValue is
    case 'ToggleBooleanValue':
      // TODO: If disabled - cleanup selectedFilters with this filter as parent
      draft.selectedFilters[payload.key] = {
        value: {
          booleanValue: !(
            draft.selectedFilters[payload.key]?.value?.booleanValue ?? false
          ),
        },
      };
      break;

    case 'ToggleMultiSelectionValue': {
      const current = draft.selectedFilters[payload.key];

      if (!current) {
        draft.selectedFilters[payload.key] = {
          values: [{ value: payload.value }],
        };
      } else {
        // If the current values contain the value we attempt to set, filter it away or if we don't find it, add it instead
        const valueExists = !!current?.values?.find(
          (v) => v.value === payload.value
        );
        let values: SmdBasSrpModelsSelectedFilterValue[] | undefined = [];
        if (valueExists) {
          // find the key in selectedFilters that has this filter as parent
          const childKey = Object.keys(draft.selectedFilters).find((key) =>
            draft.selectedFilters![key]?.values?.find(
              (v) =>
                v.parent?.key === payload.key &&
                v.parent?.value === payload.value
            )
          );
          if (childKey) {
            // toggle off / filter out all the child values that have this value as parent, since the parent is also being toggled off
            const childValues = (
              draft.selectedFilters[childKey]!.values ?? []
            ).filter(
              (f) =>
                f.parent?.key === payload.key &&
                f.parent?.value !== payload.value
            );
            childValues.length === 0
              ? delete draft.selectedFilters[childKey]
              : (draft.selectedFilters[childKey]!.values = childValues);
          }
          // toggle off the value that is already selected for this filter (which is also the parent filter)
          values = current?.values?.filter((v) => v.value !== payload.value);
        } else {
          values = [...(current?.values ?? []), { value: payload.value }];
        }

        if (!values || values.length === 0) {
          delete draft.selectedFilters[payload.key];
        } else {
          current.values = values;
        }
      }

      break;
    }

    case 'ToggleNestedMultiSelectionValue': {
      const { parent, value } = payload;

      // If the filter does not exist - create it
      if (!draft.selectedFilters[payload.key]) {
        draft.selectedFilters[payload.key] = {
          values: [],
        };
      }
      const current = draft.selectedFilters[payload.key];

      // Find all values with same parent
      const valuesForParent = current!.values?.find(
        (value) =>
          value.parent?.value === parent.value &&
          value.parent?.key === parent.key
      );

      if (!valuesForParent) {
        // No value for this parent exists
        current!.values?.push({
          parent,
          values: [value],
        });
      } else if (
        !!valuesForParent.values &&
        valuesForParent.values.length === 1 &&
        valuesForParent.values[0] === value
      ) {
        // If value is the only value for this parent, then filter away the parent
        const values = (current!.values ?? []).filter(
          (f) =>
            f.parent?.key === parent.key && f.parent?.value !== parent.value
        );
        values.length === 0
          ? delete draft.selectedFilters[payload.key]
          : (current!.values = values);
      } else {
        current!.values = [
          ...(current!.values ?? []).filter(
            (f) =>
              f.parent?.key === parent.key && f.parent?.value !== parent.value
          ),
          {
            parent,
            values: valuesForParent.values?.includes(value)
              ? valuesForParent.values.filter((v) => v !== value)
              : [...(valuesForParent.values ?? []), value],
          },
        ];
      }
      break;
    }

    case 'SetSorting':
      draft.selectedSorting = action.payload;
      break;

    default:
      console.warn(
        'Unknown action encountered when setting SearchRequestState:',
        action
      );
      return;
  }

  // Always reset when we adjust search request
  draft.pageSize = Number(process.env.NEXT_PUBLIC_PAGESIZE ?? 30);
  delete draft.page;
  return;
};
