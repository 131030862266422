export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" transform="rotate(180 8 8)" fill="#059E6F" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4983 4.93945C11.8079 5.21464 11.8357 5.68869 11.5606 5.99828L7.56056 10.4983C7.42333 10.6527 7.2285 10.7436 7.02203 10.7497C6.81556 10.7558 6.61573 10.6764 6.46967 10.5303L4.46967 8.53034C4.17678 8.23744 4.17678 7.76257 4.46967 7.46968C4.76256 7.17678 5.23744 7.17678 5.53033 7.46968L6.96789 8.90724L10.4394 5.00174C10.7146 4.69215 11.1887 4.66426 11.4983 4.93945Z"
      fill="white"
    />
  </svg>
);
SuccessIcon.displayName = 'AlertSuccessIcon';
export default SuccessIcon;
