import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStyles,
  FloatingPortal,
  Placement,
  FloatingFocusManager,
} from '@floating-ui/react';
import React, { ReactNode } from 'react';
import styles from './Popover.module.scss';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import VeryLargeCloseIcon from '@/icons/VeryLargeCloseIcon';

type PopoverProps = {
  content: ReactNode;
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  placement?: Placement;
  readMoreLink?: string;
};

export const Popover = ({
  content,
  children,
  placement = 'top',
  readMoreLink,
}: PopoverProps) => {
  const [open, setOpen] = React.useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    whileElementsMounted: autoUpdate, // TODO: This could be avoided if we just put the popover in the right container e.g. the body of the dialog
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const interactions = useInteractions([click, dismiss, role]);

  const { isMounted, styles: ttStyles } = useTransitionStyles(context, {
    duration: 250,
  });

  if (!React.isValidElement(children)) return null;

  const readMore = typeof readMoreLink !== 'undefined' && (
    <a href={readMoreLink} target="_blank" className={styles.readMoreLink}>
      Læs mere
    </a>
  );

  return (
    <>
      {React.cloneElement(
        children,
        interactions.getReferenceProps({
          ref: refs.setReference,
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
          },
        })
      )}
      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            modal={false}
            initialFocus={-1}
          >
            <div
              className={styles.popover}
              ref={refs.setFloating}
              style={{
                // Positioning styles
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                ...ttStyles,
              }}
              {...interactions.getFloatingProps()}
            >
              <div className={styles.content}>
                {content}
                {readMore}
              </div>

              <button
                className={styles.closeButton}
                onClick={() => setOpen(false)}
              >
                <VeryLargeCloseIcon />
              </button>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
