const SIZES = [
  64, 96, 140, 200, 225, 300, 400, 500, 640, 960, 1200, 1600, 4096,
];

export default function customImageLoader({ src, width, quality }) {
  if (!src.includes('billeder.bilbasen.dk')) return src;

  const nearestSize = SIZES.reduce((prev, curr) =>
    Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev
  );
  const classQuery = `S${nearestSize}X${nearestSize}`;
  const uri = new URL(src);

  uri.searchParams.set('class', classQuery);
  // uri.searchParams.set('width', width); // Maybe have to include this, if NextJS makes too much noise

  return uri;
}
