import { Popover } from '@/atoms/Popover';
import InfoIcon from '@/icons/InfoIcon';
import styles from './InfoPopover.module.scss';
import { Placement } from '@floating-ui/react';
import { ReactNode } from 'react';
import React from 'react';

type Props = {
  content?: ReactNode;
  url?: string;
  placement?: Placement;
};

export const InfoPopover = ({ content, placement, url }: Props) => {
  return (
    <Popover content={content} placement={placement} readMoreLink={url}>
      <InfoIcon className={styles.icon} />
    </Popover>
  );
};
