import React from 'react';
import {
  autoUpdate,
  useFloating,
  offset,
  size,
  useInteractions,
  useRole,
  useDismiss,
  useListNavigation,
  Placement,
} from '@floating-ui/react';
import { flip } from '@floating-ui/dom';

export const useFloatingList = (
  data?: any,
  animationFrame: boolean = false,
  maxHeight?: (availableHeight: number) => string,
  disabled?: boolean,
  placement?: Placement,
  width?: string,
  shouldFlip: boolean = true
) => {
  const [open, setOpen] = React.useState(!!data);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const virtualItemRef = React.useRef<HTMLElement | null>(null);

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    placement: placement ?? 'bottom',
    whileElementsMounted: (reference, floating, update) =>
      autoUpdate(reference, floating, update, { animationFrame }),
    open,
    onOpenChange: !disabled ? setOpen : undefined,
    middleware: [
      shouldFlip && flip(),
      offset(1),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: width ?? `${rects.reference.width}px`,
            maxHeight: maxHeight
              ? maxHeight(availableHeight)
              : `${Math.min(availableHeight, 260)}px`,
          });
        },
        padding: 16,
      }),
    ],
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    virtualItemRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  React.useEffect(() => {
    if (!!data !== open) {
      setOpen(!!data);
    }
  }, [data]);

  return {
    x,
    y,
    strategy,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    setActiveIndex,
    activeIndex,
    setOpen,
    open,
    listRef,
  };
};
