import React from 'react';
import styles from './Toast.module.scss';
import ErrorIcon from '@/icons/ErrorIcon';
import cx from 'classnames';
import WarningIcon from '@/icons/WarningIcon';
import SuccessIcon from '@/icons/SuccessIcon';

export enum Level {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

interface ToastProps {
  level: Level;
  text: string;
  className?: string;
}

const getIcon = (level: Level) => {
  switch (level) {
    case Level.ERROR:
      return <ErrorIcon />;
    case Level.WARNING:
      return <WarningIcon />;
    case Level.SUCCESS:
      return <SuccessIcon />;
  }
};

export const Toast = ({ level, text, className }: ToastProps) => {
  return (
    <div
      className={cx([className, styles.toastContainer], {
        [styles.error]: level == Level.ERROR,
        [styles.warning]: level == Level.WARNING,
        [styles.success]: level == Level.SUCCESS,
      })}
    >
      <div className={styles.icon}>{getIcon(level)}</div>
      <div className="font-size-caption">{text}</div>
    </div>
  );
};
