import styles from './Platform.module.scss';
import cx from 'classnames';

export enum PlatformType {
  Desktop = 'desktop',
  SSW = 'ssw',
}

export enum PageVariant {
  Frontpage = 'frontpage',
  SRP = 'srp',
}

type PlatformProps = {
  children?: React.ReactNode;
  type: PlatformType;
  className?: string;
};

export const Platform = ({
  children,
  type,
  className,
  ...props
}: PlatformProps) => {
  return (
    <div
      className={cx(className && className, {
        [styles.showOnDesktop]: type === PlatformType.Desktop,
        [styles.showOnSSW]: type === PlatformType.SSW,
      })}
      {...props}
    >
      {children}
    </div>
  );
};
